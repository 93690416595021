// Index
.ca-header {
  padding: 1rem 1rem 1rem 2rem !important;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.04) !important;
  .ca-page-title {
    font-size: 1.375rem;
    margin-left: 0.5rem;
    padding-right: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &.p-border-right {
      border-right: 1px solid #ADB2B9;
    }
  }
  // Search box component
  .ca-search-box {
    border-left: unset;
    padding-left: 0.375rem;
    padding-right: 0;
    border-right: unset;
    .search-wrapper.is-active {
      padding-left: 0.625rem;
      .icon-wrapper {
        margin-left:  0rem;
      }
      input {
        cursor: text;
      }
    }
    #header-search-input::-webkit-search-cancel-button {
      cursor: pointer;
    }
    input {
      border-radius: 2.5rem;
      background: #fff;
      border-width: 1px;
      line-height: 3rem;
      height: 2.5rem;
      &:hover {
        background: #fff;
      }
    }
  }
  // User profile component
  .ca-user-profile {
    max-width: 14rem;
    .avatar-icon {
      text-align: center;
      background: #BFE8FF;
      color: #035ADE;
    }
    .ca-user-info {
      font-size: 0.75rem;
      line-height: 1.25rem;
      overflow: hidden;
      & > div:first-child {
        color: #035ADE;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      & > div:last-child {
        color: #ADB2B9;
      }
    }
    .ca-user-profile-mobile .ca-user-info-wrapper {
      display: none;
    }
    .ca-user-participant-wrapper {
      border-radius: 1rem;
    }
    .dropdown-item-participant-logout {
      display: block;
      width: 100%;
      padding: 0.55rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: #7a7b97;
      text-align: inherit;
      white-space: nowrap;
      border: 0;
      color: #FF3366 !important;
    }
    .dropdown-item-participant-logout:focus, .dropdown-item-participant-logout:hover {
        border-radius: 8px !important;
        background-color: #E9ECF1 !important;
        color: #FF3366 !important;
    }
    .dropdown-item-custom-logout {
      display: block;
      width: 100%;
      padding: 0.55rem 1.5rem;
      clear: both;
      font-weight: 400;
      color: #7a7b97;
      text-align: inherit;
      white-space: nowrap;
      border: 0;
      color: #FF3366 !important;
    }
    .dropdown-item-custom-logout:focus, .dropdown-item-custom-logout:hover {
        border-radius: 8px !important;
        background-color: #E9ECF1 !important;
        color: #FF3366 !important;
    }
  }
  // User profile component
  .ca-notification {
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #1F2C41;
    margin-right: 1rem;
  }
  // Hamburger button component
  .ca-hamburger {
    .hamburger-box {
      margin-top: 0.3125rem;
    }
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background: #035ade !important;
    }
  }
}

@media only screen and (max-width: 991.98px) {
  .search-wrapper-open .ca-page-title {
    display: none;
  }
  .ca-header {
    position: fixed !important;
    right: 0;
    .ca-search-box {
      margin-left: 0;
    }
    .ca-notification {
      margin-right: 0.5rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ca-user-profile .avatar-icon-wrapper + .ca-user-info {
    display: none !important;
  }
  .ca-user-profile-mobile {
    padding: 0.5rem;
    & .ca-user-info-wrapper {
      border-bottom: 1px solid #ADB2B9;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex !important;
    }
    .ca-user-info {
      display: in;
      & > div:first-child {
        white-space: break-spaces !important;
      }
    }
  }
}

@media (min-width: 992px) {
  .app-sidebar-collapsed.app-header-fixed .app-header.ca-header {
    left: 4.5rem;
  }
}