.card-wrapper {
  height: 18.75rem;
  margin-top: 5px;
  
}

.image-text-wrapper {
  position: relative;
  max-width: 50rem;
  margin: 0 auto;
  text-align: left;
  & .content {
    border-radius: 0.75rem;
    position: absolute;
    bottom: 0;
    color: #f1f1f1;
    width: 100%;
    padding: 10px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.04);
  }
  & img {
    border-radius: 0.75rem;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
  }
  & .content {
    & .title {
      font-size: 1.125rem;
      font-weight: bold;
      line-height: 120%;
    }
    & .description {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 130%;
    }
  }
}

