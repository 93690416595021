@import '../../../variables';

.modal-wrapper {
  padding: 1.25rem;
  .brand-link-form {
    width: 100%;
  }
  .brand-name {
    display: none;
  }
}
.upload-container {
  // background: #F2FAFD;
  // border: 2px dashed #D4F1F0;
}

@media only screen and (min-width: 481px) {
  .modal-wrapper {
    padding: 1.5rem;
    .brand-name {
      display: inline;
    }
    .brand-link-form {
      width: 60%;
    }
  }
}

.ca-modal-base {
    .modal-body {
        ul, ol {
            padding-left: 1rem;
        }
    }
    .ca-modal-content-wrapper {
        padding: 2rem;
        .ca-modal-header-buttons {
            background: #F5F5F5;
            border-radius: 0.5rem;
            padding: 0.5rem 1rem;
            align-items: center;
            .ca-modal-header-label {
                font-size: 1.25rem;
            }
        }
        p {
            margin-top: 1.5rem;
        }
        button {
            min-width: unset;
        }
    }
}
.break-line {
    white-space: pre-line;
}
.ca-modal-text-editor-wrapper {
    max-width: 40rem !important;
    max-height: calc(100% - 3.5rem) !important;
}
.modal-dialog {
    &.modal-normal {
        min-width: 840px;
        .modal-content {
            min-height: 606px;
        }
    }
    .close-btn {
        font-size: 2.5rem;
        color: $Midnight;
        opacity: 1;
    }
    .modal-header {
        border-bottom: none;
    }
}

.modal-loading {
    min-height: 350px;
    font-size: 1.5rem;
    .animate-group {
        display: inline-block;
    
    .modal-loading-find {
        color: $BlackPearl;
        font-weight: bold;
        opacity: 1;
        position: absolute;
        left: 3rem;
        top: -5px;
    }
    .item-fade-out {
        color: $BlackPearl;
        font-weight: 500;
        top: -60px;
        opacity: 0.2;
        animation: animationFrames ease-out 1s;
        animation-iteration-count: 1;
        transform-origin: 50% 50%;
        -webkit-animation: animationFrames ease-out 1s;
        -webkit-animation-iteration-count: 1;
        -webkit-transform-origin: 50% 50%;
        -moz-animation: animationFrames ease-out 1s;
        -moz-animation-iteration-count: 1;
        -moz-transform-origin: 50% 50%;
        -o-animation: animationFrames ease-out 1s;
        -o-animation-iteration-count: 1;
        -o-transform-origin: 50% 50%;
        -ms-animation: animationFrames ease-out 1s;
        -ms-animation-iteration-count: 1;
        -ms-transform-origin: 50% 50%;

    }
    .item-active {
        opacity: 1;
       transition: ease-in 0.2s
    }
    .item-inactive {
        opacity: 0;
    }
}
    .bar-loading-resume {
        .progress {
        height: 25px;
        border-radius: 20px;
        }
    }

}

  
@media only screen and (min-width: 768px) {
    .ca-modal-base.ca-modal-cancel {
        width: 37.5rem;
        max-width: unset;
    }
}

  @keyframes animationFrames{
    0% {
      opacity:1;
      transform:  translate(0px, 60px)  ;
    }
    100% {
      opacity: 0.2;
      transform:  translate(0px, 0px)  ;
    }
  }
  
  @-moz-keyframes animationFrames{
    100% {
      opacity:1;
      -moz-transform:  translate(0px, 60px)  ;
    }
    0% {
      opacity:0.2;
      -moz-transform:  translate(0px, 0px)  ;
    }
  }
  
  @-webkit-keyframes animationFrames {
    0% {
      opacity:1;
      -webkit-transform:  translate(0px, 60px)  ;
    }
    100% {
      opacity:0.2;
      -webkit-transform:  translate(0px, 0px)  ;
    }
  }
  
  @-o-keyframes animationFrames {
    0% {
      opacity:1;
      -o-transform:  translate(0px, 60px)  ;
    }
    100% {
      opacity:0.2;
      -o-transform:  translate(0px, 0px)  ;
    }
  }
  
  @-ms-keyframes animationFrames {
    100% {
      opacity:1;
      -ms-transform:  translate(0px, 60px)  ;
    }
    0% {
      opacity:0.2;
      -ms-transform:  translate(0px, 0px)  ;
    }
  }
