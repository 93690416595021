:root {
    --p-red: #D21C23;
    --p-orange: #FF8F00;
    --p-border-grey: #E5E5E5;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: 'Inter';
    src:  url('./assets/fonts/Inter-Regular.otf') format('otf'),
            url('./assets/fonts/Inter-Regular.woff') format('woff'),
            url('./assets/fonts/Inter-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src:  url('./assets/fonts/Inter-SemiBold.otf') format('otf'),
            url('./assets/fonts/Inter-SemiBold.woff') format('woff'),
            url('./assets/fonts/Inter-SemiBold.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src:  url('./assets/fonts/Inter-Bold.otf') format('otf'),
            url('./assets/fonts/Inter-Bold.woff') format('woff'),
            url('./assets/fonts/Inter-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

body {
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: "Inter";
    color: #828B93 !important;
}

::-moz-placeholder { /* Firefox 19+ */
    font-family: "Inter";
    color: #828B93 !important;
}

:-ms-input-placeholder { /* IE 10+ */
    font-family: "Inter";
    color: #828B93 !important;
}

:-moz-placeholder { /* Firefox 18- */
    font-family: "Inter";
    color: #828B93 !important;
}

.layout-wrapper {
    min-height: 100vh;
    overflow-x: hidden;
}

.fixed-header {
    top: 0;
    position: sticky;
    background-color: #ffffff
}

.main-btn-width {
    width: 10rem;
}

.table-parent-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 1px !important;
    min-height: 0 !important;
}

.candidate-list-dashboard {
    flex: 1;
    overflow-x: auto;
}

body {
    padding: 0 !important;
}

th {
    z-index: 1;
}

button.btn {
    border-width: 1px;
    min-width: 6rem;
}

img {
    object-fit: cover;
}

.header {
    font-size: 2rem;
    padding: 2rem 1.5rem;
}

.header div.font-weight-bold {
    line-height: 2rem;
}

.header-button {
    width: 10.5rem !important;
    padding: 0.7rem 0px !important;
    border-radius: 4px !important;
    border-width: 1px;
    font-weight: bold !important;
    font-size: 0.8rem !important;
    text-transform: unset !important;
    font-family: "Inter" !important;
    cursor: pointer;
}

.overlay {
    position: fixed;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.4);
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: none;
    &.show {
        display: block;
    }
}

.global-header {
    padding-bottom: .875rem;
    padding-top: .75rem;
    #Logo {
        cursor: pointer;
        img {
            width: auto;
        }
    }
    .sl-icon {
        font-size: 13px;
        display: block;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center;
        right: -15px;
        top: -3px;
    }
    .sl-text {
        display: none;
    }
    .dropdown {
        margin-left: 1.5rem;
    }
    .dropdown-menu {
        min-width: 7rem;
    }
    .avatar-icon {
        box-shadow: none;
    }
    .mobile-nav {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 4rem;
        z-index: 9999;
        background: white;
        transform: translateX(-100%);
        transition: transform 0.25s;
        &.show {
            transform: translateX(0);
        }
        & > div {
            padding: 1.25rem;
        }
        #Menu, #support {
            li {
                padding: 0.875rem 0;
                font-weight: 500;
                &:hover, &.highlighted {
                    background-color: #F2F2F2;
                }
                a, div {
                    display: block;
                    width: 100%;
                    padding-left: .5rem;
                    color: #828B93;
                    &:hover, &.active {
                        color: #1E2C42;
                    }
                }
            }
        }
        #support {
            padding-top: 1rem;
            border-top: 1px solid #E5E5E5;
        }
    }
    .desktop-nav {
        display: none;
    }
    button.dropdown-item {
        color: #1E2C42;
        font-weight: 500;
    }
}

.global-footer {
    line-height: 1rem;
    color: #4E5A6B;
    border-top: 1px solid #E5E5E5;
    display: none;
}

#Profile:focus {
    outline: none;
}

#Profile {
    position: relative;

    div {
        border-radius: 50%;
        cursor: pointer;
    }

    ul {
        list-style-type: none;
        font-size: 0.85rem;
        line-height: 1.2rem;
        position: absolute;
        right: 0;
        width: 12rem;
        z-index: 1;
        border-radius: 6px;
        margin-top: 0.6rem;
        box-shadow: 0px 1px 3px rgba(30, 44, 66, 0.12), 0px 4px 12px rgba(30, 44, 66, 0.07);

        li {
            cursor: pointer;
        }
        li:hover {
            background: #FAFAFA;
        }
    }
}

.tab-width {
    min-width: 11rem;
}

.vertical-scroll {
    flex: 1 0 1px !important;
    min-height: 0 !important;
    overflow-y: auto !important;
}

.layout-item-content {
    color: #1E2C42;
    overflow: hidden;
}

.title-input-asterisk:after {
    content: ' *';
    color: #01A3A4;
}

.text-truncated {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.Background-LightGray { background-color: #f2f2f2 !important; }

.Flex-Unset { flex: unset !important; }

.BorderLeft-1 { border-left: 1px solid #E5E5E5; }

.CustomSuccessToast{
  background-color: rgb(116, 181, 116);
}

.Opacity-05{
  opacity: 0.5;
}

.font-size-1-5 {
    font-size: 1.5rem !important;
}

@mixin text-length-long($size) {
    .text-length-long-#{$size} {
      padding: 0;
      width: $size#{'%'};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }
@include text-length-long(77);
@include text-length-long(70);

.cursor-pointer {
    cursor: pointer !important;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}

.pointer-events-none {
    pointer-events: none !important;
}

.CustomLoader-Wrapper {
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
}

.border-grey {
    border-color: var(--p-border-grey) !important;
}
.error {
    & {
        color: var(--danger) !important;
    }
    .form-group {
        & > label,
        & > span {
            color: var(--danger) !important;
        }
        input,
        textarea,
        &:focus,
        & > div > div {
            border-color: var(--danger) !important;
        }
    }
    & > div {
        border-color: var(--danger) !important;
    }
}

/*----- Font weight ----*/
$font-weight-numbers: (500, 600);
@each $number in $font-weight-numbers {
    .font-weight-#{$number} {
        font-weight: $number !important;
    }
}

@media only screen and (min-width: 481px) {
    .overlay.show {
        display: none;
    }
    .global-header {
        .dropdown {
            margin-left: 1rem;
        }
        .sl-text {
            display: block;
        }
        .mobile-nav, .hamburger-icon, .sl-icon {
            display: none;
        }
        .mobile-nav.show {
            transform: translateX(-100%);
        }
        .desktop-nav {
            display: flex;
            #Menu {
                display: flex;
                margin-bottom: 0;
                li {
                    padding: 0 0.75rem;
                    font-weight: bold;
                    a {
                        color: #ffffff;
                        opacity: 0.6;
                        &.highlighted, &.active {
                            opacity: 1;
                        }
                    }
                }
            }
            img {
                margin-right: 1rem;
            }
        }
    }
    .global-footer {
        display: flex;
    }
}

/*----- Override reactstrap classes ----*/
.form-control {
    &:focus {
        border-color: #d1d2db !important;
    }
    &.is-invalid {
        background: none !important;
    }
}
.avatar-icon {
    box-shadow: none !important;
}
.dropdown-item {
    color: #1E2C42 !important;
}
.custom-switch.custom-control {
    &.disabled {
        label.custom-control-label {
            &:after, &::before {
                pointer-events: none;
            }
        }
    }
    label.custom-control-label {
        &:after, &::before {
            cursor: pointer;
        }
    }
}

/*----- Override react-datepicker classes ----*/
.react-datepicker {
    .react-datepicker__current-month {
        font-weight: bold !important;
    }
    .react-datepicker__day-name,
    .react-datepicker__day {
        color: #1E2C42 !important;
        font-weight: bold !important;
    }
    .react-datepicker__day {
        &.react-datepicker__day--outside-month {
            color: #C2C4C6 !important;
        }
        &:hover {
            color: var(--white) !important;
        }
    }
    .react-datepicker__current-month {
        color: var(--white) !important;
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--keyboard-selected:hover {
        color: var(--white) !important;
        background-color: var(--primary) !important;
        border-radius: 50% !important;
    }
    .react-datepicker__day--disabled {
        opacity: 0.6 !important;
        pointer-events: none !important;
    }
    .react-datepicker-popper {
        z-index: 999;
    }
}

/*----- Override react-chip-input classes ----*/
.react-chip-input {
    border: 1px solid#d1d2db !important;
    box-shadow: none !important;
    div[class^="styles_chip__"] {
        display: flex;
        align-items: center;
        color: #1E2C42;
    }
    div.col-auto {
        padding: 0.25rem !important;
    }
    &.rounded {
        border-radius: 0.25rem !important;
    }
    &.border {
        border: 1px solid#d1d2db !important;
    }
    input {
        min-width: 7rem;
    }
}

@media only screen and (max-width: 1440px) {
    .project-list-wrapper .card-list-wrapper .text-length-long-70 {
        padding: 0;
        width: 65%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        display: block;
    }
}

/*** Coach's avatar or Bio video  ***/
.row-wrapper {
    .left-card {
        .TypoBioVideo{
            min-height: 50%;
        }
        .BioVideoImageProfile{
            object-fit: cover;
            font-size: 9rem;
            height: 25rem !important;
            border-style: none !important;
        }
        .BioVideoImage {
            min-height: 22.5rem;
            .DropZone .DropZone-Button {
                width: 6.5rem;
                height: 2rem;
                background: #E0FDEC;
                border: 1px solid #01A3A4;
                border-radius: 0.25rem;
                color: #01A3A4;
                display: none;
            }
            .DropZone:hover .DropZone-Button {
                display: flex;
                cursor: pointer;
                position: absolute;
                z-index: 1;
            }
            .DropZone .group-button {
                display: contents;
            }
        }
        .BioVideoImage .DropZone > img{
            object-fit: cover;
            height: 100%;
            width: 100%;
            max-height: 22.5rem;
        }
        .custom-avatar {
            background-color: #F2FAFD;
            color: #01A3A4;
            font-size: 9rem;
            font-weight: bold;
        }
        .CustomizeShowing video::-webkit-media-controls-timeline{
            margin-left: 2.4rem;
        }
        .CustomizeShowing  video::-webkit-media-controls-current-time-display{
            margin-left: 2.4rem;
        }
        .showCssVideo video::-webkit-media-controls-fullscreen-button {
            display: none;
        }
        .showCssVideo video::-webkit-media-controls-timeline {
            display: none;
        }
        .showCssVideo  video::-webkit-media-controls-current-time-display{
            display: none;
        }
        .showCssVideo video::-webkit-media-controls-time-remaining-display {
            display: none;
        }
        .showCssVideo video::-webkit-media-controls-volume-slider-container {
            display: none;
        }
        .showCssVideo video::-webkit-media-controls-mute-button {
            display: none;
        }
        .showCssVideo video::-webkit-media-controls-volume-slider {
            display: none;
        }
        .videoTagHTML:focus{
            outline: none;
        }
        .PlayBio{
            position: absolute;
            top: 22rem;
            left: 2rem;
            cursor: pointer;

            .Verital-Align-Top{
                vertical-align: top;
            }
            .Cursor-None{
                cursor: default;
            }
        }
    }
}

@font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/Nunito-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Nunito';
    src: url('./assets/fonts/Nunito-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  body {
    font-size: 0.875rem;
    color: #1F2C41;
    font-weight: normal;
    font-style: normal;
    font-family: "Nunito",  sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .flex-row {
    flex-direction: row;
  }
  
  .height-auto {
    height: 'auto' !important;
    // background-color: green;
  }
  
  .red {
    background-color: red;
  }
  
  .green {
    background-color: green;
  }
  
  
  .app-wrapper-bg-light {
    background: #fafafa !important;
  }

  .ca-content {
    padding: 10rem 2rem 2rem 2rem !important;
    min-height: 100vh;
    transition: all 0.2s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  
  // OVERRIDE DEFAULT TEMPLATE
  .dropdown-item {
    color: #7a7b97 !important;
  }
  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #EBF3FF;
    color: #070919 !important;
  }
  .Toastify__toast-container {
    .Toastify__toast {
      font-family: "Nunito", "Heebo", sans-serif;
    }
  }
  
  // COMMON CLASS
  .cursor-pointer {
    cursor: pointer !important;
  }
  .error-message {
    color: #FF6285;
  }
  .text-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .text-ellipsis-multiline {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-word;
  }
  .font-size-10 { font-size: 0.625rem; }
  .font-size-12 { font-size: 0.75rem !important; }
  .font-size-14 { font-size: 0.875rem; }
  .font-size-16 { font-size: 1rem !important; }
  .font-size-18 { font-size: 1.125rem; }
  .font-size-20 { font-size: 1.25rem; }
  .font-size-22 { font-size: 1.375rem; }
  .font-size-24 { font-size: 1.5rem; }
  .font-size-48 { font-size: 3rem; }
  .line-height-16 { line-height: 1rem; }
  .line-height-24 { line-height: 1.5rem; }
  .mt-40 { margin-top: 2.5rem; }
  .mt-12 { margin-top: 0.75rem; }
  .mt-14 { margin-top: 0.875rem; }
  .mt-20 { margin-top: 1.25rem; }
  .mt-24 { margin-top: 1.5rem; }
  .mt-32 { margin-top: 2rem; }
  .mb-14 { margin-bottom: .75rem; }
  .mb-18 { margin-bottom: 1.125rem; }
  .mb-20 { margin-bottom: 1.25rem; }
  .mb-24 { margin-bottom: 1.5rem; }
  .mb-32 { margin-bottom: 2rem; }
  .ml-20 { margin-left: 1.25rem; }
  .pt-20 { padding-top: 1.25rem; }
  .pt-24 { padding-top: 1.5rem; }
  .pl-24 { padding-left: 1.5rem; }
  .py-12 { padding: 0.75rem 0 !important; }
  .p-32 { padding: 2rem; }
  .mw-32 { max-width: 2rem; }
  .letter-spacing--0-3 { letter-spacing: -0.3px; }
  .size-16 {
    width: 1rem !important;
    height: 1rem !important;
  }
  .size-32 {
    width: 2rem !important;
    height: 2rem !important;
  }
  
  .ca-loading-text:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis-animation steps(4,end) 900ms infinite;
    content: "\2026";
    width: 0px;
  }
  @keyframes ellipsis-animation {
    to {
      width: 1.25rem;
    }
  }
  
  @media only screen and (min-width: 992px) {
    .ca-content {
      padding-left: 6.5rem !important;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .ca-content {
      padding: 10rem 0rem 2rem 0rem !important;
    }
  }

  .padding-top-list-job {
    padding-top: 6rem !important;
  }

  @import './variables';

  .bg-PasianGreen {
    background-color: $PasianGreen !important;
}
.bg-Midnight {
    background-color: $Midnight !important;
}
.bg-Aluminium {
    background-color: $Aluminium !important;
}
.bg-Chambray {
    background-color: $Chambray !important;
}
.bg-White {
    background-color: $White !important;
}
.bg-Snow {
    background-color: $Snow !important;
}
.bg-Whisper {
    background-color: $Whisper !important;
}
.bg-SurfieGreen {
    background-color: $SurfieGreen !important;
}
.bg-MediumTurquoise {
    background-color: $MediumTurquoise !important;
}
.bg-MorningGlory {
    background-color: $MorningGlory !important;
}
.bg-Black70 {
    background-color: $Black70 !important;
}
.bg-Emerald {
    background-color: $Emerald !important;
}
.bg-RadicalRed {
    background-color: $RadicalRed !important;
}
.bg-Red {
    background-color: $Red !important;
}
.bg-Amber {
    background-color: $Amber !important;
}
.bg-Green {
    background-color: $Green !important;
}
.bg-DarkOrange {
    background-color: $DarkOrange !important;
}
