.unsupported-browser-wrapper {
  height: 100vh;
  & img {
    max-width: 100%;
  }
  & p {
    color: #828B93;
  }
  & a {
    text-decoration: underline;
    color: var(--primary);
    &:hover {
      color: darken(#01A3A4, 20);
    }
  }
  & .browser-icon > img {
    width: 4rem;
    height: 4rem;
  }
}
@media screen and (max-width: 425px) {
  .unsupported-browser-wrapper {
    & .browser-icon > img {
      width: 3rem;
      height: 3rem;
    }
  }
}