.ca-button {
  border-radius: 2.5rem !important;
  box-shadow: none !important;
  border-color: #1F2C41 !important;
  color: #1F2C41 !important;
  font-size: 0.875rem !important;
  vertical-align: middle !important;
  font-weight: bold !important;
  background: #f5f5f5 !important;
  padding: 0.3rem 1.25rem !important;
  min-height: 2.8rem !important;
  min-width: 16rem !important;
  display: inline-block !important;
  white-space: nowrap !important;
  &:hover,
  &:active,
  &:focus {
    border-color: #1F2C41 !important;
    box-shadow: none !important;
    color: #1F2C41 !important;
    background: none !important;
  }
  &.btn-blue {
    border-color: #035ADE !important;
    color: #035ADE !important;
    background: #fff !important;
    &:hover,
    &:active,
    &:focus {
      border-color: #035ADE !important;
      box-shadow: none !important;
      color: #035ADE !important;
      background: #fff !important;
    }
  }
  &.btn-solid {
    background-color: #1F2C41 !important;
    color: #fff !important;
    border-color: #1F2C41 !important;
    &:hover,
    &:active,
    &:focus {
      color: #fff !important;
      background-color: #1F2C41 !important;
    }
    &.btn-blue {
      background-color: #035ADE !important;
      color: #fff !important;
      border-color: #035ADE !important;
      &:hover,
      &:active,
      &:focus {
        color: #fff !important;
        background-color: #035ADE !important;
      }
    }
  }
  &.btn-salmon {
    background-color: #F86158 !important;
    color: #FFFFFF !important;
    border-color: #F86158 !important;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 1rem !important;
    line-height: 1.375rem;
    width: 18.5rem;
  }
  &.btn-solid-salmon {
    background-color: #F86158 !important;
    border-color: #F86158 !important;
    &:hover {
      background-color: #F86158 !important;
      border-color: #F86158 !important;
    }
  }
}