@import '../../../variables';

.wrapper-filter {
  height: 64px;
  border-radius: 8px;
  background-color: $Background;
  display: flex;
  padding: 32px;
  align-items: center;
  .wrapper-select {
    min-width: 180px;
    margin: 0 0.5rem;
    font-size: 12px;
    > div {
      min-height: 32px;
      border-radius: 2.5rem;
      border: 1px solid $White;
    }
  }
  nav {
    position: absolute;
    right: 32px;
  }
}