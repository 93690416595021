.ca-app-sidebar{
  background: #01A3A4;
  width: 4.5rem !important;
  .ca-logo {
    a { border-bottom: 1px solid rgba(255, 255, 255, .3); }
  }
  .ca-sidebar--content {
    .sidebar-menu-collapsed {
      padding: 0.66rem 0;
    }
    a {
      color: rgba(#fff, 0.6);
      padding: 0;
      height: 2.5rem;
      width: 2.5rem;
      transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out !important;
      border-radius: 50% !important;
      &.active {
        color: rgba(#fff, 1);
      }
      &:hover {
        background-color: #1A4688;
        color: rgba(#fff, 1);
      }
    }
    & > div:last-child {
      padding: 1rem 0.5rem;
    }
  }
}
