@import '../../variables'; // ./variables

.ca-card-form {
    width: 100% !important;
    & .job-title {
        max-width: 50rem;
    }
}

.wrapper-popover {
    background-color: $Midnight;
    color: $White;
    min-width: 360px;
    .popover-header {
        background-color: $Midnight;
        border-bottom: 1px solid $Midnight;
        &::before {
            border-bottom: 1px solid $Midnight !important;
        }
    }
    .popover-body {
        color: $White;
    }
    .arrow::after, .arrow::before {
        border-bottom-color: $Midnight !important;
        border-top-color: $Midnight !important;
    }
}

.justify-content-flex-end {
    justify-content: flex-end
}

.skill-matched {
    background-color: #FF8F00;
}

.skill-no-matched {
    background-color: #E5E5E5;
}

$drawerWidth: 30rem;
.job-data-wrapper {
  .Content {
    flex-grow: 1;
    transition-timing-function:ease-out;
    transition: 0.5s;
  }

  .ContentShift {
    transition-timing-function:ease-in;
    transition: 0.5s;
    margin-right: 0;
    width: (100 % - $drawerWidth);
  }

  .Message {
    font-size: 1.5rem;
  }

  .jobs-list-table {
    height: 572px;
    overflow-x: hidden;
  }

  .title-jobsearch,
  .name-company,
  .job-date {
    font-size: .875rem;
  }

  .title-jobsearch {
    color: #1e2c42;
    font-weight: 500;
  }

  .name-company {
    color:#828b93;
  }

  .table {
    color: #1e2c42;
  }

  tr.hover {
    background-color: #fffbf2;
  }

  .portal-jobTitle {
    color: #1e2c42;
  }

  .job-data-detail {
    max-height: calc(100vh - 24rem);
    & .job-detail-button {
      height: 40px;
      width: 150px;
      border-radius: 2.5rem;
      box-shadow: none;
    }
    & .track-button {
      background-color: #FFFF;
      border-color: #01a3a4;
      color: #01a3a4;
      margin-left: 16px;
    }
  }
}

@media only screen and (max-width: 755px) {
  .job-data-wrapper {
    .job-tool-bar {
     flex-direction: column;
    }
    .job-filter {
      flex-direction: column;
      & > div {
        margin-top: .5rem;
        margin-left: 0 !important;
        button, div {
          width: 100%;
          max-width: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  .job-data-wrapper {
    flex-direction: column;
    .job-data-detail {
      max-height: 30rem;
      overflow: auto;
      width: 100% !important;
      margin: 1rem 0;
    }
  }
  .job-tool-bar {
    & > div:last-child {
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .job-data-wrapper {
    .job-tool-bar {
     flex-direction: column;
    }
    .job-filter {
      margin-top: .5rem;
      & > div:first-child {
        margin-left: 0 !important;
      }
    }
    .job-pagination {
      margin-top: .5rem;
    }
  }
}

@media only screen and (max-width: 1367px) {
    .job-data-wrapper {
        .ContentShift {
            width: $drawerWidth !important;
        }
    }
}

@media only screen and (max-height: 769px) {
  .card-wrapper{
      height: 14rem;
  }
  .jobs-list-table {
    height: 400px !important;
    overflow-x: hidden;
  }
}