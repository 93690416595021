@import '../../variables'; // ./variables

.wrapper-popover {
    background-color: $Midnight;
    color: $White;
    min-width: 360px;
    .popover-header {
        background-color: $Midnight;
        border-bottom: 1px solid $Midnight;
        &::before {
            border-bottom: 1px solid $Midnight !important;
        }
    }
    .popover-body {
        color: $White;
    }
    .arrow::after, .arrow::before {
        border-bottom-color: $Midnight !important;
        border-top-color: $Midnight !important;
    }
}

.resume-wrapper {
    height: 39.313rem;
    background-color: #FFFFFF !important;
    width: 98%;
    box-shadow: 0px 0.375rem 1rem rgba(0, 0, 0, 0.04);
    border-radius: 0.813rem;
    margin-top: 5px;
    padding: 32px;
    & .image-resume {
        width: 3.125rem;
        height: 3.125rem;
        transition: transform .2s;
    }
    & .title {
        font-style: normal;
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 1.875rem;
    }
    & .description {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 160%;
    }
    & .upload-resume-button {
        height: 2.5rem;
        width: 12.5rem;
        background-color: #1F2C41;
        border-radius: 2.5rem;
        color: #FFFFFF;
    }
    & .resume-upload-wrapper {
        width: 100%;
        min-height: 188px;
        border: 2px dashed $PasianGreen;
        padding: 30px 10px;
        border-radius: 10px;
        background-color: #f0ffff;
        p {
          font-size: 16px;
          margin-top: 16px;
          margin-bottom: 16px;
          span {
            color: $SurfieGreen;
            cursor: pointer;
          }
        }
        .file-name {
          font-size: 18px;
        }
        &:hover{
            border-color: #aba1a1;
        }
    }
}

.justify-content-flex-end {
    justify-content: flex-end
}

.skill-matched {
    background-color: #FF8F00;
}

.skill-no-matched {
    background-color: #E5E5E5;
}

@media only screen and (max-width: 992px) {
  .resume-wrapper {
      width: 100%;
  }
}

@media only screen and (max-height: 769px) {
  .resume-wrapper {
      height: 30rem;
  }
  .card-wrapper{
      height: 14rem;
  }
}