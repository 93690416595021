@import '../../../variables';

.table-search-box {
  border-left: unset;
  padding-right: 0;
  border-right: unset;
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
  .search-wrapper.is-active {
    padding-left: 0.625rem;
    .icon-wrapper {
      margin-left:  0rem;
    }
    input {
      cursor: text;
    }
  }
  #header-search-input::-webkit-search-cancel-button {
    cursor: pointer;
  }
  input {
    border-radius: 2.5rem;
    background: $Background !important;
    border-color: $Background !important;
    line-height: 3rem;
    height: 44px;
    width: 240px;
    &:hover, &:focus {
      background: $Background;
      border-color: $Background !important;
    }
  }
  .wrapper-switch {
    align-self: center;
    .btn-switch {
      top: 0.4rem;
    }
    .profile {
      color: $PasianGreen;
      font-weight: 500;
    }
  }
}